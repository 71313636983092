@import "variables";
@import "base";
@import "base.date";
@import "base.time";
@import "default";
@import "default.time";
@import "classic";
@import "classic.time";

.form-control {
	&.pickadate, &.pickatime {
		background-color: $input-bg;
	}
}

th.picker__weekday {
	text-align: center;
}

// Today, clear, close
.picker__button--clear:before,
.picker__button--close:before {
	color: $body-color;
    border-color: $body-color;
}

.picker__button--clear:hover:before,
.picker__button--close:hover:before {
	color: $primary;
	border-color: $primary;
}

.picker__button--today:before {
	border-top-color: $body-color;
}

.picker__button--today:hover:before {
	border-top-color: $primary;
}

// Next & prev month
.picker__nav--prev:hover:before {
    border-right-color: #fff;
}
.picker__nav--next:hover:before {
    border-left-color: #fff;
}

// Today
.picker__day--today:hover:before, .picker__day--highlighted:before {
	border-top-color: #fff;
}

// When the picker is opened, the input element is “activated”.
.picker__input.picker__input--active {
	@if $enable-shadows {
		box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
		box-shadow: $input-focus-box-shadow;
	}
}