$enable-responsive-font-sizes: true;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #AEA79F;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #333;
$gray-900: #212529;
$black:    #000;

$blue:   #007bff;
$indigo: #6610f2;
$purple: #772953;
$pink:   #e83e8c;
$red:    #DF382C;
$orange: #E95420;
$yellow: #EFB73E;
$green:  #38B44A;
$teal:   #20c997;
$cyan:   #17a2b8;

$primary:   $orange;
$secondary: $gray-500;
$success:   $green;
$info:      $cyan;
$warning:   $yellow;
$danger:    $red;
$light:     $gray-200;
$dark:      $purple;

$navbar-padding-y: 0;

$link-color: $primary;

$navbar-dark-hover-color: rgba($white, .75);
$navbar-dark-active-color: $white;

$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;

// Font size
/*$h1-font-size: $font-size-base * 2.3;
$h2-font-size: $font-size-base * 1.9;
$h3-font-size: $font-size-base * 1.7;
$h4-font-size: $font-size-base * 1.45;
$h5-font-size: $font-size-base * 1.25;
$small-font-size: 75%;*/

// Form
$input-btn-padding-y: .4rem;
$input-border-width: 2px;
$btn-border-width: 2px;
$custom-select-border-width: 2px;
$custom-select-focus-box-shadow: 0 0 0 .2rem rgba($primary, .25);

// Breadcrumb
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");