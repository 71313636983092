$selectize-font-smoothing: inherit;
$selectize-font-family: inherit;
$selectize-font-size: inherit;
$selectize-line-height: $input-btn-line-height;

$selectize-color-text: $input-color;
$selectize-color-border: $input-border-color;
$selectize-color-highlight: rgba(255,237,40,0.4);
$selectize-color-input: $input-bg;
$selectize-color-input-full: $input-bg;
$selectize-color-input-error: $danger;
$selectize-color-input-error-focus: darken($selectize-color-input-error, 10%);
$selectize-color-disabled: $input-bg;
$selectize-color-item: $light;
$selectize-color-item-text: color-contrast($light);
$selectize-color-item-border: rgba(0,0,0,0);
$selectize-color-item-active: $component-active-bg;
$selectize-color-item-active-text: $component-active-color;
$selectize-color-item-active-border: rgba(0,0,0,0);
$selectize-color-optgroup: $dropdown-bg;
$selectize-color-optgroup-text: $dropdown-header-color;
$selectize-color-optgroup-border: $dropdown-divider-bg;
$selectize-color-dropdown: $dropdown-bg;
$selectize-color-dropdown-border: $selectize-color-border;
$selectize-color-dropdown-border-top: $input-border-color;
$selectize-color-dropdown-item-active: $dropdown-link-hover-bg;
$selectize-color-dropdown-item-active-text: $dropdown-link-hover-color;
$selectize-color-dropdown-item-create-text: $selectize-color-text;
$selectize-color-dropdown-item-create-active-text: $dropdown-link-hover-color;
$selectize-lighten-disabled-item: 30%;
$selectize-lighten-disabled-item-text: 30%;
$selectize-lighten-disabled-item-border: 30%;
$selectize-opacity-disabled: 0.5;
$selectize-shadow-input: inherit;
$selectize-shadow-input-focus: inherit;
$selectize-shadow-input-error: inset 0 1px 1px rgba(0, 0, 0, .075);
$selectize-shadow-input-error-focus: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($selectize-color-input-error, 20%);
$selectize-border: $input-border-width solid $input-border-color;
$selectize-dropdown-border: 1px solid $selectize-color-dropdown-border;
$selectize-border-radius: $input-border-radius;

$selectize-width-item-border: 0;

$selectize-padding-x: $input-padding-x;
$selectize-padding-y: $input-padding-y;
$selectize-padding-dropdown-item-x: $dropdown-item-padding-x;
$selectize-padding-dropdown-item-y: $dropdown-item-padding-y;
$selectize-padding-item-x: 0.2rem;
$selectize-padding-item-y: 0.1rem;
$selectize-margin-item-x: 0.2rem;
$selectize-margin-item-y: 0.1rem;

$selectize-caret-margin: 0;
$selectize-caret-margin-rtl: 0 4px 0 -2px;

$selectize-max-height-dropdown: 200px;

$selectize-arrow-size: 5px;
$selectize-arrow-color: $selectize-color-text;
$selectize-arrow-offset: $selectize-padding-x;

@import "selectize";

.selectize-dropdown, .selectize-dropdown.form-control {
	height: auto;
	padding: 0;
	margin: 2px 0 0 0;
	z-index: 1060;
	background: $selectize-color-dropdown;
	border: 1px solid $dropdown-border-color;
	@include selectize-border-radius($dropdown-border-radius);
	@include selectize-box-shadow(0 6px 12px rgba(0,0,0,.175));
}

.selectize-dropdown {
	.optgroup-header {
		font-size: $small-font-size;
		line-height: $input-btn-line-height;
	}
	.optgroup:first-child:before {
		display: none;
	}
	.optgroup:before {
		content: ' ';
		display: block;
		height: 0;
		margin: $dropdown-divider-margin-y 0;
		overflow: hidden;
		border-top: 1px solid $dropdown-border-color;
		margin-left: $selectize-padding-dropdown-item-x * -1;
		margin-right: $selectize-padding-dropdown-item-x * -1;
	}
}

.selectize-dropdown-content {
	padding: 5px 0;
	color: $dropdown-link-color;
}

.selectize-dropdown-header {
	padding: $selectize-padding-dropdown-item-y * 2 $selectize-padding-dropdown-item-x;
}

.selectize-input {
	font-size: $font-size-base;
	min-height: $input-height;
	@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
	@include transition(#{"border-color ease-in-out .15s, box-shadow ease-in-out .15s"});
	@include selectize-border-radius($selectize-border-radius);
	vertical-align:top;

	&.dropdown-active {
		@include selectize-border-radius($selectize-border-radius);
	}
	&.dropdown-active::before {
		display: none;
	}
	&.focus {
		$color: $input-focus-border-color;
		$color-rgba: rgba(red($color), green($color), blue($color), .6);
		border-color: $color;
		outline: 0;
		@include box-shadow(#{"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px ${color-rgba}"});
	}
	input {
		font-size: $font-size-base;
		&::placeholder {
			color: $input-placeholder-color;
			opacity: 1;
		}
	}
}

.has-error .selectize-input {
	border-color: $selectize-color-input-error;
	@include selectize-box-shadow($selectize-shadow-input-error);

	&:focus {
		border-color: $selectize-color-input-error-focus;
		@include selectize-box-shadow($selectize-shadow-input-error-focus);
	}
}

.selectize-control {
	&.multi {
		.selectize-input.has-items {
			padding-left: $selectize-padding-x - $selectize-padding-item-x;
			padding-right: $selectize-padding-x - $selectize-padding-item-x;
		}
		.selectize-input > div {
			@include selectize-border-radius($selectize-border-radius);
		}
	}
}

.form-control.selectize-control {
	padding: 0;
	height: auto;
	border: none;
	background: none;
	@include selectize-box-shadow(none);
	@include selectize-border-radius(0);
}

.input-group .form-control:not(:last-child) .selectize-input, .input-group .custom-select:not(:last-child) .selectize-input {
	@include border-end-radius(0);
}

.input-group .form-control:last-child .selectize-input, .input-group .custom-select:last-child .selectize-input {
	@include border-start-radius(0);
}