.sms-container {
	overflow: hidden;
	overflow-x: auto;
}

.table.sms {
	margin-bottom: 10px;
}

.sms .img img {
	width: 70px;
	height: 70px;
	padding: 0;
}

.sms .message {
	position: relative;

	&:before {
		content: ' ';
		position: absolute;
		width: 0;
		height: 0;
		top: 0;
		left: 0;
		border: 6px solid;
		border-color: #fff #fff transparent transparent;
	}

	& > div {
		border: 0;
		padding: 12px;
		box-shadow: 2px 2px 1px -1px rgba(0, 0, 0, 0.15);
		display: inline-block;
		background-color: #fff;
		margin-left: 12px;
		margin-right: 4px;

		.figure {
			max-width: 280px;
			margin: 5px;
		}
	}

}

.sms.reverse {
	.message {
		text-align: right;

		.blockquote p {
			text-align: left;
		}

		&:before {
			border: 0;
		}

		& > div {
			border-bottom: 12px solid #e2fffd;
			border-right: 12px solid transparent;
			box-shadow: -2px 2px 1px -1px rgba(0, 0, 0, 0.15);
			padding-bottom: 0;
			margin-left: 2px;
			margin-right: 0;
			background-color: #e2fffd;
			background-clip: padding-box;
		}
	}
}

.td.img {
	width: 70px;
	vertical-align: top;
}

.reverse .td.img {
	vertical-align: bottom;
}

.sms.other .message  > div {
	border-bottom: 12px solid #e2fffd;
	background-color: #e2fffd;
}

.new {
	.sms .message {
		& > div {
			background-color: #edffd7;
		}

		&:before {
			border-color: #edffd7 #edffd7 transparent transparent;
		}
	}
}

@include color-mode(dark) {

}

.message blockquote p:empty {
	display: none;
}

.message-item {
	position: relative;
}

.caret {
	vertical-align: middle;
	display: inline-block;
	border-top: 4px dashed;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

.collapsed .caret {
	border-bottom: 4px solid rgba(0, 0, 0, 0);
	border-top: 4px solid rgba(0, 0, 0, 0);
	border-left: 4px solid;
}

.message-item.opened .caret {
	border-top: 0;
	border-left: 4px solid rgba(0, 0, 0, 0);
	border-right: 4px solid rgba(0, 0, 0, 0);
	border-bottom: 4px solid;
}

.message-delete-form, .reply-delete-form {
	display: inline-block;
}

@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
	.sms .img img {
		width: 50px;
		height: 50px;
	}

	.td.img {
		width: 50px;
	}
}

// Embed
.sms {
	.message {
		.embed-responsive {
			min-width: 350px;
		}
	}
}

@include color-mode(dark) {
	.sms .message {
		&:before {
			border-color: var(--bs-table-bg) var(--bs-table-bg) transparent transparent;
		}

		& > div {
			background-color: var(--bs-table-bg);
		}
	}

	.new {
		.sms .message {
			&::before {
				border-color: #192e00 #192e00 transparent transparent;
			}

			& > div {
				background-color: #192e00;
			}
		}
	}

	.sms.reverse {
		.message > div {
			border-bottom: 12px solid #00302c;
			background-color: #00302c;
		}
	}

	.sms.other .message  > div {
		border-bottom: 12px solid #00302c;
		background-color: #00302c;
	}
}

.message-item {
	.comment-form {
		overflow: hidden;
		transition: max-height 0.5s ease-out;
		max-height: 0;
	}

	&.opened .comment-form {
		max-height: 10000px;
		transition: max-height 0.5s ease-in;
	}
}