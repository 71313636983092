#flash-message-container {
	display: flex;
	flex-direction: column;
	position: fixed;
	align-items: flex-end;
	top: 70px;
	left: 0;
	width: 100vw;
	padding-right: 5px;
	pointer-events: none;
	z-index: 3000;
}

.flash-message {
	overflow: hidden;
	width: 50%;
	min-width: 250px;
	max-width: 750px;
	opacity: 0.9;
	z-index: 2000;
	pointer-events: auto;
	transform: translateX(100%);
	transition: transform 0.6s ease-out;

	&.in {
		transform: translateX(0);
	}
}