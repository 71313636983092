// Table plan
.plan-table:not(.disabled):hover {
	rect, circle, text, .table, .text {
		stroke: green;
		cursor: pointer;
	}
}

.plan-table.selected {
	rect, circle, .table {
		fill: green;
	}

	text, .text {
		stroke: white;
		fill: white;
	}
}

.plan-table.disabled {
	rect, circle, .table {
		fill: grey;
	}

	text, .text {
		fill: white;
	}
}