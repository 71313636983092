/*!
 * Default mobile-first, responsive styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */

@include media-breakpoint-down(sm) {
	/**
	 * Note: the root picker element should *NOT* be styled more than what’s here.
	 */
	.picker {}


	/**
	 * Make the holder and frame fullscreen.
	 */
	.picker__holder,
	.picker__frame {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		// Nudge everything off-screen to begin with.
		transform: translateY(100%);
	}


	/**
	 * The holder should overlay the entire screen.
	 */
	.picker__holder {

		// Fill the screen and fix the position.
		position: fixed;

		// Fade out the background, then immediately shift the holder out of view.
		transition: background $speed-animate-in ease-out, transform 0s $speed-animate-in;

		// Avoid flickering of the page on webkit browsers
		-webkit-backface-visibility: hidden;
	}



	/**
	 * The frame that bounds the box contents of the picker.
	 */
	.picker__frame {

		position: absolute;

		// Specify the min & max widths and center align it.
		margin: 0 auto;
		min-width: $picker-min-width;
		max-width: $picker-max-width;
		width: 100%; // For IE9 & 10 to keep it centered.

		// Hide it to begin with@mixin
		@include opacity( 0 );

		// Animate the frame in and out of view.
		transition: all $speed-animate-in ease-out;

		// For `small` screens..@mixin
		@media ( min-height: $breakpoint-small ) {

			// Reveal what’s beyond to allow drop shadows, et al.
			overflow: visible;

			// Align to the bottom edge instead of top.
			top: auto;
			bottom: -100%;

			// Prevent it from overflowing over the top edge.
			max-height: 80%;
		}

		// For `medium` screens..@mixin
		@media ( min-height: $breakpoint-medium ) {

			// Move away from the bottom edge.
			margin-bottom: 7.5%;
		}
	}

	/**
	 * The wrapper sets the stage to vertically align the box contents.
	 */
	.picker__wrap {
		display: table;
		width: 100%;
		height: 100%;

		// For `small` screens, remove the “middle-aligned” styling
		@media ( min-height: $breakpoint-small ) {
			display: block;
		}
	}



	/**
	 * The box contains all the picker contents.
	 */
	.picker__box {
		background: $bg-white;

		// To start with, vertically align to center
		display: table-cell;
		vertical-align: middle;

		// For `tiny` screens, increase the font size a bit
		@media ( min-height: $breakpoint-tiny ) {
			font-size: 1.25em;
		}

		// For `small` screens..@mixin
		@media ( min-height: $breakpoint-small ) {

			// Remove the “middle-aligned” styling
			display: block;

			// Increase the font size a bit more
			font-size: 1.33em;

			// Add the borders except the bottom one
			border: 1px solid $border-grey;
			border-top-color: lighten( $border-grey, 7% );
			border-bottom-width: 0;

			// Make ‘em rounded at the top corners
			border-radius: $picker-border-radius $picker-border-radius 0 0;

			// And finally, add a nice shadow
			box-shadow: $picker-box-shadow;
		}

		// For `medium` screens..@mixin
		@media ( min-height: $breakpoint-medium ) {

			// Increase the font size.
			font-size: 1.5em;

			// Reveal all borders and round all corners.
			border-bottom-width: 1px;
			border-radius: $picker-border-radius;
		}
	}


	/**
	 * When the picker opens...
	 */
	.picker--opened {

		// Immediately move the holder to the top edge then fade in an overlay
		.picker__holder {

			// Move it to the top edge
			transform: translateY(0);

			// Show a translucent black background (order is important for IE)
			background: transparent;
			-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#1E000000,endColorstr=#1E000000)"; // IE8
			zoom: 1;
			background: rgba(0,0,0,.32); // Normal browsers

			// Animate in the background
			transition: background $speed-animate-in ease-out;
		}


		// Smoothly move the content to the top edge while fading it in
		.picker__frame {

			// Move to the top edge
			transform: translateY(0);

			// Ånd then reveal the content
			@include opacity( 1 );

			// For `small` screens, move to the bottom edge instead
			@media ( min-height: $breakpoint-small ) {
				top: auto;
				bottom: 0;
			}
		}
	}
}